<template>
  <div>
    <div>
      <iframe
        id="iframe-overview"
        ref="myElement"
        frameborder="1"
        :height="`${height}px`"
        width="100%"
        scrolling="no"
        :src="`${VUE_APP_URL_FRONTEND_NEW_MITRA}/overview?iframe=true`"
      />
    </div>
    <SidebarOverview
      v-if="sidebarData !== null"
      :id="sidebarData.id"
      :visible="visible"
      :email="sidebarData.user.email"
      :cardname="sidebarData.card.name"
      :fullname="sidebarData.user.name"
      :last-number="sidebarData.card.last_number"
      :status="sidebarData.status"
      :history="sidebarData.history"
      @close-modal="closeModal"
    />
    <ModalConfirmation
      @handle-submit="onSubmit"
    />
  </div>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import SidebarOverview from './Sidebar.vue'
import ModalConfirmation from './ModalConfirmation.vue'

export default {
  components: { SidebarOverview, ModalConfirmation },
  data() {
    return {
      sidebarData: null,
      visible: false,
      idArrayModalConfirmation: [],
      height: 0,
      VUE_APP_URL_FRONTEND_NEW_MITRA: process.env.VUE_APP_URL_FRONTEND_NEW_MITRA,
    }
  },
  mounted() {
    window.addEventListener('message', event => {
      if (event.origin === this.VUE_APP_URL_FRONTEND_NEW_MITRA) {
        const message = event.data
        if (message.type === 'sidebar') {
          this.sidebarData = message.data
          setTimeout(() => {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-overview')
          }, 1)
        }

        if (message.type === 'MODAL_CONFIRMATION_CHANGE_STATUS') {
          this.openModal()
          this.idArrayModalConfirmation = message.data.id
        }

        if (message.type === 'SET_DIMENSION') {
          this.height = Math.floor(message.data.height)
        }
      }
    })
  },
  methods: {
    refetchTable() {
      const otherWindow = document.getElementById('iframe-overview').contentWindow
      otherWindow.postMessage({ type: 'REFECTH_TABLE_OVERVIEW' }, '*')
    },
    async onSubmit() {
      try {
        const response = await newAxiosIns.request({
          method: 'put',
          url:
            '/komcards/api/v1/mitra/ticket/on-process',
          data: {
            id: this.idArrayModalConfirmation,
          },
        })

        if (response.status === 200) {
          this.$bvModal.hide('modal-confirmation-change-status')

          setTimeout(() => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                text: 'Issue was succesfully move to “On Process”',
                variant: 'success',

              },
            }, 1000)
          }, 500)

          this.refetchTable()

          return
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: 'Issue was failed move to “On Process”',
            autoHideDelay: 1000,
            variant: 'danger',
          },
        }, 1000)
      }
      this.$bvModal.hide('modal-confirmation-change-status')
    },
    openModal() {
      this.$root.$emit('bv::show::modal', 'modal-confirmation-change-status')
    },
    closeModal(e) {
      if (e === 'with-refetch') {
        this.refetchTable()
      }
      this.sidebarData = null
    },
  },
}
</script>

<style scoped></style>
